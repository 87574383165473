import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Login from './Components/Login';
import Register from './Components/Register';
import Dashboard from './Components/Dashboard';
import Table from './Components/Table';
import View from './Components/View';
import Privacy from './Components/Privacy';
import Account from './Components/Account';
import Airtable from './Components/Airtable';

export default function Routes() {
    return (
        <Router>
            <Switch>
                <Route
                    exact={true}
                    path='/login'
                    component={Login}
                />
                <Route
                    exact={true}
                    path='/'
                    component={Login}
                />
                <Route
                    exact={true}
                    path='/register'
                    component={Register}
                />
                <Route
                    exact={true}
                    path='/dashboard'
                    component={Dashboard}
                />
                <Route
                    exact={true}
                    path='/table'
                    component={Table}
                />
                <Route
                    exact={true}
                    path='/view'
                    component={View}
                />
                <Route
                    exact={true}
                    path='/privacy'
                    component={Privacy}
                />
                <Route
                    exact={true}
                    path='/account'
                    component={Account}
                />
                <Route
                    exact={true}
                    path='/airtable'
                    component={Airtable}
                />
            </Switch>
        </Router>
    );
}