import React, {useState} from 'react';
import {Row, Col, Layout, Input} from 'antd';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

const {Header} = Layout;
const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled(FlexCol)`
  padding: 3rem;
  align-items: center;
  justify-content: space-between;
`;

const Titlehead = {
    fontWeight: '800',
    fontSize: '20px',
    color: '#5E72E4'
};

const heading = {
    fontSize: '30px',
    color: '#525f7f'
};

function Home() {
    const [username, setUsername] = useState('harshvardhhan');
    const forked = `${'https://github.com/'}${username}${'/airtable-website'}`;
    const edited = `${'https://github.com/'}${username}${'/airtable-website/edit/master/src/configuration.js'}`;
    return (
        <div className='App'>
            <HeaderComponent/>
            <Container>
                <h1 style={heading}>
                    Make your Airtable go live with your custom domain for free 😃and forever.
                </h1>
                <div style={{width: '80%'}}>
                    <Row style={{marginTop: '50px'}}>
                        <Col span={2}>
                            <h1 style={heading}>1.</h1>
                        </Col>
                        <Col span={22}>
                            <h1 style={heading}>
                                Sign Up for
                                <a
                                    style={{color: '#5E72E4'}}
                                    onClick={() => window.open('https://github.com/join')}
                                >
                                    {' Github '}
                                </a>
                            </h1>
                        </Col>
                    </Row>
                    <Row style={{marginTop: '30px'}}>
                        <Col span={2}>
                            <h1 style={heading}>2.</h1>
                        </Col>
                        <Col span={22}>
                            <h1 style={heading}>
                                Sign Up for
                                <a
                                    style={{color: '#5E72E4'}}
                                    onClick={() => window.open('https://dashboard.render.com/register?next=/')}
                                >
                                    {' Render '}
                                </a>
                                Via Github
                            </h1>
                            <img
                                style={{
                                    width: '100%',
                                    borderImageWidth: '2px',
                                    borderColor: '#00000'
                                }}
                                src={require('./Images/render.png')}
                            />
                        </Col>
                    </Row>
                    <Row style={{marginTop: '30px'}}>
                        <Col span={2}>
                            <h1 style={heading}>3.</h1>
                        </Col>
                        <Col span={22}>
                            <h1 style={heading}>
                                Open
                                <a
                                    style={{color: '#5E72E4'}}
                                    onClick={() => window.open('https://github.com/TrakBit/Airtable-website')}
                                >
                                    {' Airtable Template '}
                                </a>
                                on Github and Fork it.
                            </h1>
                            <img
                                style={{
                                    width: '100%',
                                    borderImageWidth: '2px',
                                    borderColor: '#00000'
                                }}
                                src={require('./Images/fork.png')}
                            />
                        </Col>
                    </Row>
                    <Row style={{marginTop: '30px'}}>
                        <Col span={2}>
                            <h1 style={heading}>4.</h1>
                        </Col>
                        <Col span={22}>
                            <h1 style={heading}>
                                Get your
                                <a
                                    style={{color: '#5E72E4'}}
                                >{' GITHUB_USERNAME '}</a>
                                from here (like harshvardhhan in my case) and Enter it in the field below
                                <div style={{marginTop: '30px'}}>
                                    <Input
                                        addonBefore={'Github Username'}
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                        placeholder='Github Username'
                                        size='large'
                                    />
                                </div>
                            </h1>
                            <img
                                style={{
                                    width: '100%',
                                    borderImageWidth: '2px',
                                    borderColor: '#00000'
                                }}
                                src={require('./Images/username.png')}
                            />
                        </Col>
                    </Row>
                    <Row style={{marginTop: '30px'}}>
                        <Col span={2}>
                            <h1 style={heading}>5.</h1>
                        </Col>
                        <Col span={22}>
                            <h1 style={heading}>
                                open
                                <a
                                    style={{color: '#5E72E4'}}
                                    onClick={() => window.open(forked)}
                                >
                                    {' '}{forked}{' '}
                                </a>
                                and click on
                                <a
                                    style={{color: '#5E72E4'}}
                                >
                                    {' Deploy To Render '}
                                </a>
                                button
                            </h1>
                            <img
                                style={{
                                    width: '100%',
                                    borderImageWidth: '2px',
                                    borderColor: '#00000'
                                }}
                                src={require('./Images/deploy.png')}
                            />
                        </Col>
                    </Row>
                    <Row style={{marginTop: '30px'}}>
                        <Col span={2}>
                            <h1 style={heading}>6.</h1>
                        </Col>
                        <Col span={22}>
                            <h1 style={heading}>
                                Click on Approve and wait for it to create the site
                            </h1>
                            <img
                                style={{
                                    width: '100%',
                                    borderImageWidth: '2px',
                                    borderColor: '#00000'
                                }}
                                src={require('./Images/approve.png')}
                            />
                        </Col>
                    </Row>
                    <Row style={{marginTop: '30px'}}>
                        <Col span={2}>
                            <h1 style={heading}>7.</h1>
                        </Col>
                        <Col span={22}>
                            <h1 style={heading}>
                                Once site created click on Service
                            </h1>
                            <img
                                style={{
                                    width: '100%',
                                    borderImageWidth: '2px',
                                    borderColor: '#00000'
                                }}
                                src={require('./Images/service.png')}
                            />
                        </Col>
                    </Row>
                    <Row style={{marginTop: '30px'}}>
                        <Col span={2}>
                            <h1 style={heading}>8.</h1>
                        </Col>
                        <Col span={22}>
                            <h1 style={heading}>
                                Open Application
                            </h1>
                            <img
                                style={{
                                    width: '100%',
                                    borderImageWidth: '2px',
                                    borderColor: '#00000'
                                }}
                                src={require('./Images/application.png')}
                            />
                        </Col>
                    </Row>
                    <Row style={{marginTop: '30px'}}>
                        <Col span={2}>
                            <h1 style={heading}>9.</h1>
                        </Col>
                        <Col span={22}>
                            <h1 style={heading}>
                                Now you can open your live URL 😃
                            </h1>
                            <img
                                style={{
                                    width: '100%',
                                    borderImageWidth: '2px',
                                    borderColor: '#00000'
                                }}
                                src={require('./Images/url.png')}
                            />
                        </Col>
                    </Row>
                    <Row style={{marginTop: '30px'}}>
                        <Col span={2}>
                            <h1 style={heading}>10.</h1>
                        </Col>
                        <Col span={22}>
                            <h1 style={heading}>
                                Now Edit the project on this URL
                                <a
                                    style={{color: '#5E72E4'}}
                                    onClick={() => window.open(edited)}
                                >
                                    {' '}{edited}{' '}
                                </a>
                            </h1>
                            <img
                                style={{
                                    width: '100%',
                                    borderImageWidth: '2px',
                                    borderColor: '#00000'
                                }}
                                src={require('./Images/edit.png')}
                            />
                        </Col>
                    </Row>
                    <Row style={{marginTop: '30px'}}>
                        <Col span={2}>
                            <h1 style={heading}>11.</h1>
                        </Col>
                        <Col span={22}>
                            <h1 style={heading}>
                                Now scroll down and click on commit changes, your website data will be updated.
                                <a
                                    style={{color: '#5E72E4'}}
                                    onClick={() => window.open(edited)}
                                >
                                    {' '}{edited}{' '}
                                </a>
                            </h1>
                            <img
                                style={{
                                    width: '100%',
                                    borderImageWidth: '2px',
                                    borderColor: '#00000'
                                }}
                                src={require('./Images/commit.png')}
                            />
                        </Col>
                    </Row>
                    <Row style={{marginTop: '30px'}}>
                        <Col span={2}>
                            <h1 style={heading}>12.</h1>
                        </Col>
                        <Col span={22}>
                            <h1 style={heading}>
                                Now you can follow few simple steps to connect site with your custom domain by clicking
                                <a
                                    onClick={() => window.open('https://render.com/docs/custom-domains')}
                                    style={{color: '#5E72E4'}}
                                >
                                    {'  here '}
                                </a>
                                and you are all done 🥳 Great Job
                            </h1>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    );
}

const HeaderComponent = () => {
    return (
        <Header style={{background: '#FFFFFF'}}>
            <Row>
                <Col span={1}>
                    <div style={{marginTop: '10px', width: '10px'}}>
                        <Icon/>
                    </div>
                </Col>
                <Col span={16}>
                    <Link to={'/'}><h1 style={Titlehead}>AirPlug</h1></Link>
                </Col>
            </Row>
        </Header>
    );
};

function Icon() {
    return (
        <div style={{width: '40px'}}>
            <svg
                xmlns='http://www.w3.org/2000/svg'
                fillRule='evenodd'
                strokeLinejoin='round'
                strokeMiterlimit='1.414'
                clipRule='evenodd'
                viewBox='0 0 50 50'
            >
                <path
                    fill='#5E72E4'
                    fillRule='nonzero'
                    stroke='#5E72E4'
                    strokeWidth='2.1'
                    d='M49.833 24.917c0 13.739-11.178 24.917-24.916 24.917a1.5 1.5 0 01-1.5-1.5V36.259c-5.606-.739-9.952-5.537-9.952-11.342a1.5 1.5 0 011.5-1.5h3.073V13.94a1.5 1.5 0 113 0v9.477h7.757V13.94a1.5 1.5 0 113 0v9.477h3.074a1.5 1.5 0 011.5 1.5c0 5.805-4.346 10.603-9.952 11.342v10.524c11.388-.773 20.416-10.285 20.416-21.866C46.833 12.832 37.002 3 24.917 3S3 12.832 3 24.917c0 4.599 1.408 9 4.071 12.727a1.5 1.5 0 01-2.442 1.744A24.763 24.763 0 010 24.917C0 11.178 11.178 0 24.917 0c13.738 0 24.916 11.178 24.916 24.917z'
                    transform='matrix(.95123 0 0 .9576 .99 1.183)'
                />
            </svg>
        </div>
    );
}


export default Home;