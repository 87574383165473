import fetch from './Config';

export const register = (name, email, password) => {
    const body = {name, email, password};
    return fetch.post('/register/', body);
};

export const login = (email, password) => {
    const body = {email, password};
    return fetch.post('/login/', body);
};

export const getUser = () => {
    const headers = {
        Authorization: 'Token ' + localStorage.getItem('token')
    };
    const body = {token: localStorage.getItem('token')};
    return fetch.post('/user/', body, {headers});
};

export const forgotPassword = (email) => {
    const body = {email};
    return fetch.post('/forgot/', body);
};

export const resetPassword = (
    currentPassword,
    newPassword,
    confirmPassword,
    token
) => {
    const headers = {
        Authorization: 'Token ' + localStorage.getItem('token')
    };
    const body = {
        currentPassword,
        newPassword,
        confirmPassword,
        token
    };
    return fetch.post('/resetpassword/', body, {headers});
};

export const saveAirtable = (token, api_key, base_key, table_name) => {
    const headers = {
        Authorization: 'Token ' + localStorage.getItem('token')
    };
    const body = {token, api_key, base_key, table_name};
    return fetch.post('/airtable/', body, {headers});
};

export const getTables = (token) => {
    const headers = {
        Authorization: 'Token ' + localStorage.getItem('token')
    };
    const body = {token};
    return fetch.post('/tables/', body, {headers});
};

export const getRows = (token, table_id) => {
    const headers = {
        Authorization: 'Token ' + localStorage.getItem('token')
    };
    const body = {token, table_id};
    return fetch.post('/rows/', body, {headers});
};

export const getAllRows = (token, table_id) => {
    const headers = {
        Authorization: 'Token ' + localStorage.getItem('token')
    };
    const body = {token, table_id};
    return fetch.post('/allrows/', body, {headers});
};

export const deleteTable = (token, table_id) => {
    const headers = {
        Authorization: 'Token ' + localStorage.getItem('token')
    };
    const body = {token, table_id};
    return fetch.post('/deletetable/', body, {headers});
};

export const syncTable = (token, table_id) => {
    const headers = {
        Authorization: 'Token ' + localStorage.getItem('token')
    };
    const body = {token, table_id};
    return fetch.post('/synctable/', body, {headers});
};

export const authenticate = (token) => {
    const headers = {
        Authorization: 'Token ' + token
    };
    return fetch.get('/authenticate/', {headers});
};