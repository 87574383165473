import React, {useState, useEffect} from 'react';
import {Row, Col, Layout, Input, notification, Avatar} from 'antd';
import styled from 'styled-components';
import {Link, useHistory} from 'react-router-dom';
import {getUser, resetPassword} from './../Api/Api';
import {
    InfoCircleTwoTone,
    UserOutlined
} from '@ant-design/icons';

const {Header} = Layout;
const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled(FlexCol)`
  padding: 3rem;
  align-items: center;
  justify-content: space-between;
`;

const OutlineButton = styled.button`
  height: 42px;
  width: 100px;
  background-color: #FFFFFF;
  color: #5E72E4;
  border-color: #5E72E4;
  border-width: 4px;
  font-weight: 600;
  font-size: 14px;
  border-radius: 0.25rem;
  line-height: 1.5;
  -webkit-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
`;

const Button = styled.button`
  height: 42px;
  background-color: #5E72E4;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 14px;
  border-color: #5E72E4;
  border-radius: 0.25rem;
  line-height: 1.5;
`;

const Titlehead = {
    fontWeight: '800',
    fontSize: '20px',
    color: '#5E72E4'
};

const head = {
    fontWeight: '500',
    fontSize: '20px',
    color: '#32325D'
};

const heading = {
    fontSize: '20px',
    color: '#525f7f'
};

function Home() {
    const history = useHistory();

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    useEffect(() => {
        async function setData() {
            const emailData = await getUser();
            setEmail(emailData.data.email);
            setName(emailData.data.name);
        }
        setData();
    }, []);

    const logout = () => {
        localStorage.clear();
        history.push('/');
    };

    const errorMessage = (errorMsg) => {
        notification.open({
            message:
    <div style={{fontSize: '30px'}}>
        <Row>
            <Col span={3}>
                <InfoCircleTwoTone/>
            </Col>
            <Col span={15}>
                {errorMsg}
            </Col>
        </Row>
    </div>,
            description: '',
            onClick: () => {
                notification.close();
            }
        });
    };

    const reset = async () => {
        const resetData = await resetPassword(
            currentPassword,
            newPassword,
            confirmPassword,
            localStorage.getItem('token')
        );
        errorMessage(resetData.data.message);
    };

    return (
        <div className='App'>
            <HeaderComponent logout={logout}/>
            <Row>
                <Col span={8}>
                    <Container>
                        <h1 style={heading}>
                            Account
                        </h1>
                        <div
                            className='App-login'
                            style={{borderRadius: 0, marginTop: '50px'}}
                        >
                            <div style={{width: '90%', marginLeft: '16px'}}>
                                <div style={{marginTop: '4%'}}>
                                    <h1 style={heading}>{'ACCOUNT DETAILS'}</h1>
                                </div>
                                <div style={{marginTop: '4%'}}>
                                    <Avatar
                                        size='large'
                                        style={{backgroundColor: '#5E72E4'}}
                                        icon={<UserOutlined/>}
                                    />
                                </div>
                                <div style={{paddingTop: '4%'}}>
                                    <h1 style={heading}>{name}</h1>
                                </div>
                                <div style={{paddingTop: '4%'}}>
                                    <h1 style={heading}>{email}</h1>
                                </div>
                            </div>
                        </div>

                    </Container>
                </Col>

                <Col span={8}>
                    <Container>
                        <h1 style={heading}>
                            Change Password
                        </h1>
                        <div
                            className='App-login'
                            style={{borderRadius: 0, marginTop: '50px'}}
                        >
                            <div style={{width: '90%', marginLeft: '16px'}}>
                                <div style={{marginTop: '4%'}}>
                                    <h4 style={head}>CHANGE</h4>
                                </div>
                                <div style={{paddingTop: '4%'}}>
                                    <Input
                                        addonBefore={'Current Password'}
                                        value={currentPassword}
                                        onChange={(e) => setCurrentPassword(e.target.value)}
                                        placeholder='Password'
                                        type={'password'}
                                    />
                                </div>
                                <div style={{paddingTop: '4%'}}>
                                    <Input
                                        addonBefore={'New Password'}
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        placeholder='Password'
                                        type={'password'}
                                    />
                                </div>
                                <div style={{paddingTop: '4%'}}>
                                    <Input
                                        addonBefore={'Confirm Password'}
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        placeholder='Password'
                                        type={'password'}
                                    />
                                </div>
                                <div style={{paddingTop: '15%', paddingBottom: '5%'}}>
                                    <Button
                                        style={{width: '100%'}}
                                        onClick={reset}
                                        type='primary'
                                    >
                                        CHANGE
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Container>
                </Col>
            </Row>
        </div>
    );
}

const HeaderComponent = ({logout}) => {
    return (
        <Header style={{background: '#FFFFFF'}}>
            <Row>
                <Col span={1}>
                    <Icon/>
                </Col>
                <Col span={18}>
                    <Link to={'/dashboard'}><h1 style={Titlehead}>AirPlug</h1></Link>
                </Col>
                <Col span={2}/>
                <Col
                    span={2}
                    style={{marginLeft: '10px'}}
                >
                    <OutlineButton onClick={() => logout()}>LOGOUT</OutlineButton>
                </Col>
            </Row>
        </Header>
    );
};

function Icon() {
    return (
        <div style={{marginTop: '10px', width: '40px'}}>
            <svg
                xmlns='http://www.w3.org/2000/svg'
                fillRule='evenodd'
                strokeLinejoin='round'
                strokeMiterlimit='1.414'
                clipRule='evenodd'
                viewBox='0 0 50 50'
            >
                <path
                    fill='#5E72E4'
                    fillRule='nonzero'
                    stroke='#5E72E4'
                    strokeWidth='2.1'
                    d='M49.833 24.917c0 13.739-11.178 24.917-24.916 24.917a1.5 1.5 0 01-1.5-1.5V36.259c-5.606-.739-9.952-5.537-9.952-11.342a1.5 1.5 0 011.5-1.5h3.073V13.94a1.5 1.5 0 113 0v9.477h7.757V13.94a1.5 1.5 0 113 0v9.477h3.074a1.5 1.5 0 011.5 1.5c0 5.805-4.346 10.603-9.952 11.342v10.524c11.388-.773 20.416-10.285 20.416-21.866C46.833 12.832 37.002 3 24.917 3S3 12.832 3 24.917c0 4.599 1.408 9 4.071 12.727a1.5 1.5 0 01-2.442 1.744A24.763 24.763 0 010 24.917C0 11.178 11.178 0 24.917 0c13.738 0 24.916 11.178 24.916 24.917z'
                    transform='matrix(.95123 0 0 .9576 .99 1.183)'
                />
            </svg>
        </div>
    );
}

export default Home;