import React, {useState, useEffect} from 'react';
import {Row, Col, Layout, Card, notification} from 'antd';
import styled from 'styled-components';
import {Link, useHistory} from 'react-router-dom';
import {
    CheckCircleTwoTone
} from '@ant-design/icons';
import {getTables, deleteTable, syncTable, authenticate} from './../Api/Api';

const {Header} = Layout;
const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled(FlexCol)`
  padding: 3rem;
  align-items: center;
  justify-content: space-between;
`;

const OutlineButton = styled.button`
  height: 42px;
  width: 100px;
  background-color: #FFFFFF;
  color: #5E72E4;
  border-color: #5E72E4;
  border-width: 4px;
  font-weight: 600;
  font-size: 14px;
  border-radius: 0.25rem;
  line-height: 1.5;
  -webkit-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
`;

const Button = styled.button`
  height: 42px;
  background-color: #5E72E4;
  color: #FFFFFF;
  font-weight: 600;
  font-size: 14px;
  border-radius: 0.25rem;
  line-height: 1.5;
  border-color: #5E72E4;
  -webkit-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
`;

const EditButton = styled.div`
  height: 22px;
  width: 80px;
  background-color: #6078ff;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 12px;
  border-radius: 0.25rem;
  line-height: 1.9;
  text-align: center;
`;

const DeleteTag = styled.div`
  height: 22px;
  width: 80px;
  background-color: #f67280;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 12px;
  border-radius: 0.25rem;
  line-height: 1.9;
  text-align: center;
`;

const Titlehead = {
    fontWeight: '800',
    fontSize: '20px',
    color: '#5E72E4'
};

const failedNotification = (notificationMsg) => {
    notification.open({
        message:
    <div style={{fontSize: '30px'}}>
        <CheckCircleTwoTone twoToneColor='#52c41a'/>
        {notificationMsg}
    </div>,
        description: '',
        onClick: () => {
            notification.close();
        }
    });
};

function Dashboard() {
    const history = useHistory();
    const [tables, setTables] = useState([]);

    const logout = () => {
        localStorage.clear();
        history.push('/');
    };

    const account = () => {
        history.push('/account');
    };

    useEffect(() => {
        async function setConfig() {
            const token = localStorage.getItem('token');
            try {
                await authenticate(token);
                const tableData = await getTables(token);
                setTables(tableData.data.tables);
            } catch (error) {
                logout();
            }
        }

        setConfig();
    }, []);

    const deleteTableAction = async (table_id) => {
        const token = await localStorage.getItem('token');
        const tableData = await deleteTable(token, table_id);
        setTables(tableData.data.tables);
    };

    const syncTableAction = async (table_id) => {
        const token = await localStorage.getItem('token');
        syncTable(token, table_id);
        await failedNotification('  Table Synced');
    };

    return (
        <div className='App'>
            <HeaderComponent
                logout={logout}
                account={account}
            />
            <Container>
                <Link
                    to={{
                        pathname: '/table',
                        state: {
                            table_name: '',
                            base_key: '',
                            api_key: ''
                        }
                    }}
                >
                    <Button> Add Table</Button>
                </Link>
                {
                    tables.map((value, i) => {
                        return (
                            <Card
                                className='Plug-card'
                                key={i}
                                style={{
                                    borderRadius: '2px',
                                    width: '380px',
                                    marginTop: '4%',
                                    borderColor: '#ccc'
                                }}
                            >
                                <Row>
                                    <Col span={14}>
                                        <h1>{value.table_name}</h1>
                                    </Col>
                                    <Col span={1}/>
                                    <Col span={6}>
                                        <Link
                                            to={{
                                                pathname: '/view',
                                                state: {
                                                    table_id: value.table_id,
                                                    table_name: value.table_name,
                                                    base_key: value.base_key,
                                                    api_key: value.api_key
                                                }
                                            }}
                                        >
                                            <Button> View</Button>
                                        </Link>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={6}>
                                        <Link
                                            to={{
                                                pathname: '/table',
                                                state: {
                                                    table_name: value.table_name,
                                                    base_key: value.base_key,
                                                    api_key: value.api_key
                                                }
                                            }}
                                        >
                                            <EditButton><b>SETUP</b></EditButton>
                                        </Link>
                                    </Col>
                                    <Col span={1}/>
                                    <Col span={6}>
                                        <a>
                                            <DeleteTag onClick={() => deleteTableAction(value.table_id)}>
                                                <b>DELETE</b>
                                            </DeleteTag>
                                        </a>
                                    </Col>
                                    <Col span={2}/>
                                    <Col span={6}>
                                        <Button onClick={() => syncTableAction(value.table_id)}> Sync</Button>
                                    </Col>
                                </Row>
                            </Card>
                        );
                    })
                }

            </Container>
        </div>
    );
}

const HeaderComponent = ({logout, account}) => {
    return (
        <Header style={{background: '#FFFFFF'}}>
            <Row>
                <Col span={1}>
                    <div style={{marginTop: '10px', width: '10px'}}>
                        <Icon/>
                    </div>
                </Col>
                <Col span={16}>
                    <Link to={'/dashboard'}><h1 style={Titlehead}>AirPlug</h1></Link>
                </Col>
                <Col span={2}>
                    <OutlineButton onClick={() => window.open('https://play.google.com/store/apps/details?id=com.trakbit.airplug&hl=en_US')}>ANDROID</OutlineButton>
                </Col>
                &ensp;
                <Col
                    span={2}
                >
                    <OutlineButton onClick={() => account()}>ACCOUNT</OutlineButton>
                </Col>
                &ensp;
                <Col
                    span={2}
                >
                    <OutlineButton onClick={() => logout()}>LOGOUT</OutlineButton>
                </Col>
            </Row>
        </Header>
    );
};

function Icon() {
    return (
        <div style={{width: '40px'}}>
            <svg
                xmlns='http://www.w3.org/2000/svg'
                fillRule='evenodd'
                strokeLinejoin='round'
                strokeMiterlimit='1.414'
                clipRule='evenodd'
                viewBox='0 0 50 50'
            >
                <path
                    fill='#5E72E4'
                    fillRule='nonzero'
                    stroke='#5E72E4'
                    strokeWidth='2.1'
                    d='M49.833 24.917c0 13.739-11.178 24.917-24.916 24.917a1.5 1.5 0 01-1.5-1.5V36.259c-5.606-.739-9.952-5.537-9.952-11.342a1.5 1.5 0 011.5-1.5h3.073V13.94a1.5 1.5 0 113 0v9.477h7.757V13.94a1.5 1.5 0 113 0v9.477h3.074a1.5 1.5 0 011.5 1.5c0 5.805-4.346 10.603-9.952 11.342v10.524c11.388-.773 20.416-10.285 20.416-21.866C46.833 12.832 37.002 3 24.917 3S3 12.832 3 24.917c0 4.599 1.408 9 4.071 12.727a1.5 1.5 0 01-2.442 1.744A24.763 24.763 0 010 24.917C0 11.178 11.178 0 24.917 0c13.738 0 24.916 11.178 24.916 24.917z'
                    transform='matrix(.95123 0 0 .9576 .99 1.183)'
                />
            </svg>
        </div>
    );
}

export default Dashboard;