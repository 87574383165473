import React, {useState, useEffect} from 'react';
import {Row, Col, Layout, Card} from 'antd';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {getAllRows} from './../Api/Api';

const {Header} = Layout;
const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled(FlexCol)`
  padding: 3rem;
  align-items: center;
  justify-content: space-between;
`;

const Titlehead = {
    fontWeight: '800',
    fontSize: '20px',
    color: '#5E72E4'
};

function View({location}) {
    const [rows, setRows] = useState([]);

    useEffect(() => {
        async function setConfig() {
            const token = localStorage.getItem('token');
            const tableData = await getAllRows(token, location.state.table_id);
            const filteredRows = tableData.data.rows.filter((value) => {
                if (value.table_id === location.state.table_id) {
                    return value;
                }
            });
            setRows(filteredRows);
        }
        setConfig();
    }, []);

    return (
        <div className='App'>
            <HeaderComponent/>
            <Container>
                {
                    rows.map((value, i) => {
                        return (
                            <Card
                                className='Plug-card'
                                key={i}
                                style={{
                                    borderRadius: '2px',
                                    width: '420px',
                                    marginTop: '4%',
                                    borderColor: '#ccc'
                                }}
                            >
                                {JSON.parse(value.record)[Object.keys(JSON.parse(value.record))[0]]}
                            </Card>
                        );
                    })
                }

            </Container>
        </div>
    );
}

const HeaderComponent = () => {
    return (
        <Header style={{background: '#FFFFFF'}}>
            <Row>
                <Col span={18}>
                    <Link to={'/dashboard'}><h1 style={Titlehead}>AirPlug</h1></Link>
                </Col>
                <Col span={2}/>
            </Row>
        </Header>
    );
};

export default View;