import React from 'react';
import {Layout, Menu} from 'antd';
import styled from 'styled-components';
import {
    DatabaseOutlined
} from '@ant-design/icons';

const {Sider} = Layout;

const FilterType = styled.div`
  margin-left: 20px;
  height: 22px;
  max-width: 80%;
  background-color: rgba(147, 231, 195, 0.5);
  color: #FFFFFF;
  font-weight: 500;
  font-size: 15px;
  border-radius: 0.25rem;
  line-height: 1.5;
  text-align: center;
`;

const TableSidebar = ({location}) => {
    return (
        <Sider style={{backgroundColor: '#FFFFFF'}}>
            <div style={{marginTop: '15px'}}>
                <FilterType>
                    <a style={{color: '#1aae6f'}}>
                        <b>{location.state.table_name}</b>
                    </a>
                </FilterType>
                <Menu
                    selectedKeys={[1]}
                    style={{minHeight: '71vh', backgroundColor: '#FFFFFF'}}
                    theme='light'
                >
                    <Menu.Item key='1'>
                        <DatabaseOutlined/>
                        Connect Table
                    </Menu.Item>
                </Menu>
            </div>
        </Sider>
    );
};

export default TableSidebar;