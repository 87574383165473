import React, {useState} from 'react';
import {Row, Col, Layout, Input} from 'antd';
import styled from 'styled-components';
import {Link, useHistory} from 'react-router-dom';
import TableSidebar from './TableSidebar';
import {saveAirtable} from '../Api/Api';
import Lottie from 'react-lottie';
import * as Tick from './tick.json';
import * as Cross from './cross.json';

const {Header} = Layout;
const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled(FlexCol)`
  padding: 3rem 3rem 3rem 3rem;
  align-items: left;
  justify-content: space-between;
`;

const OutlineButton = styled.button`
  height: 42px;
  width: 100px;
  background-color: #FFFFFF;
  color: #5E72E4;
  border-color: #5E72E4;
  border-width: 4px;
  font-weight: 600;
  font-size: 14px;
  border-radius: 0.25rem;
  line-height: 1.5;
  -webkit-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
`;

const SaveButton = styled.div`
  height: 40px;
  width: 100%;
  background-color: #5E72E4;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 15px;
  border-radius: 0.25rem;
  line-height: 2.7;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const Titlehead = {
    fontWeight: '800',
    fontSize: '20px',
    color: '#5E72E4'
};

const heading = {
    fontSize: '20px',
    color: '#525f7f'
};

function Table({location}) {
    const [api_key, setApiKey] = useState(location.state.api_key);
    const [base_key, setBaseKey] = useState(location.state.base_key);
    const [table_name, setTableName] = useState(location.state.table_name);
    const [tickMotionStopped, setTickMotion] = useState(true);
    const [saved, setSaved] = useState(-1);
    const history = useHistory();

    const logout = () => {
        localStorage.clear();
        history.push('/');
    };

    const save = async () => {
        const token = localStorage.getItem('token');
        const savedData = await saveAirtable(
            token,
            api_key,
            base_key,
            table_name);
        setSaved(savedData.data.save);
        setTickMotion(false);
    };

    return (
        <div className='App'>
            <HeaderComponent logout={logout}/>
            <Layout style={{backgroundColor: '#FFFFFF'}}>

                <TableSidebar location={location}/>
                <Layout style={{height: '90vh', backgroundColor: '#f5f9fb'}}>
                    <Container>
                        <div
                            style={{marginLeft: '15%', marginTop: '5%', width: '70%', marginBottom: '5vh'}}
                            className='App-form'
                        >
                            <Container>
                                <Row>
                                    <h1 style={{fontSize: '22px'}}>Connect Table</h1>
                                </Row>

                                <hr style={{color: '#ececec', width: '100%'}}/>

                                <Row style={{marginTop: '50px'}}>
                                    <Col span={1}>
                                        <h1 style={heading}>1.</h1>
                                    </Col>
                                    <Col span={21}>
                                        <h1 style={heading}>
                                            Fill Airtable API Details
                                        </h1>
                                        <h4 style={{fontSize: '16px', color: '#525f7f'}}>
                                            Get Airtable API Details from
                                            <a
                                                onClick={() => window.open('https://airtable.com/api')}
                                                style={{color: '#5E72E4'}}
                                            >
                                                {' here'}
                                            </a>
                                        </h4>
                                    </Col>
                                    <Col span={2}>
                                        <ListCheckComponent
                                            listCheck={saved}
                                            tickMotionStopped={tickMotionStopped}
                                        />
                                    </Col>
                                </Row>

                                <Row style={{marginTop: '50px'}}>
                                    <Col span={1}/>
                                    <Col span={21}>
                                        <Row>
                                            <Col span={10}>
                                                API Key
                                            </Col>
                                            <Col span={14}>
                                                <Input
                                                    placeholder='API Key'
                                                    value={api_key}
                                                    onChange={(e) => setApiKey(e.target.value)}
                                                />
                                            </Col>
                                        </Row>
                                        <div style={{marginTop: '10px'}}>
                                            <Row>
                                                <Col span={10}>
                                                    Base Key
                                                </Col>
                                                <Col span={14}>
                                                    <Input
                                                        placeholder='Base Key'
                                                        value={base_key}
                                                        onChange={(e) => setBaseKey(e.target.value)}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                        <div style={{marginTop: '10px'}}>
                                            <Row>
                                                <Col span={10}>
                                                    Table Name
                                                </Col>
                                                <Col span={14}>
                                                    <Input
                                                        placeholder='Table Name'
                                                        value={table_name}
                                                        onChange={(e) => setTableName(e.target.value)}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>

                                <Row style={{marginTop: '50px'}}>
                                    <Col span={1}/>
                                    <Col span={21}>
                                        <a>
                                            <SaveButton onClick={save}>
                                                {'SAVE TABLE'}
                                            </SaveButton>
                                        </a>
                                    </Col>
                                </Row>

                            </Container>
                        </div>
                    </Container>
                </Layout>
            </Layout>
        </div>
    );
}

const HeaderComponent = ({logout}) => {
    return (
        <Header style={{background: '#FFFFFF'}}>
            <Row>
                <Col span={1}>
                    <div style={{marginTop: '10px', width: '10px'}}>
                        <Icon/>
                    </div>
                </Col>
                <Col span={18}>
                    <Link to={'/dashboard'}><h1 style={Titlehead}>AirPlug</h1></Link>
                </Col>
                <Col span={2}/>
                <Col
                    span={2}
                    style={{marginLeft: '10px'}}
                >
                    <OutlineButton onClick={() => logout()}>LOGOUT</OutlineButton>
                </Col>
            </Row>
        </Header>
    );
};

function Icon() {
    return (
        <div style={{width: '40px'}}>
            <svg
                xmlns='http://www.w3.org/2000/svg'
                fillRule='evenodd'
                strokeLinejoin='round'
                strokeMiterlimit='1.414'
                clipRule='evenodd'
                viewBox='0 0 50 50'
            >
                <path
                    fill='#5E72E4'
                    fillRule='nonzero'
                    stroke='#5E72E4'
                    strokeWidth='2.1'
                    d='M49.833 24.917c0 13.739-11.178 24.917-24.916 24.917a1.5 1.5 0 01-1.5-1.5V36.259c-5.606-.739-9.952-5.537-9.952-11.342a1.5 1.5 0 011.5-1.5h3.073V13.94a1.5 1.5 0 113 0v9.477h7.757V13.94a1.5 1.5 0 113 0v9.477h3.074a1.5 1.5 0 011.5 1.5c0 5.805-4.346 10.603-9.952 11.342v10.524c11.388-.773 20.416-10.285 20.416-21.866C46.833 12.832 37.002 3 24.917 3S3 12.832 3 24.917c0 4.599 1.408 9 4.071 12.727a1.5 1.5 0 01-2.442 1.744A24.763 24.763 0 010 24.917C0 11.178 11.178 0 24.917 0c13.738 0 24.916 11.178 24.916 24.917z'
                    transform='matrix(.95123 0 0 .9576 .99 1.183)'
                />
            </svg>
        </div>
    );
}

const tickOptions = {
    loop: false,
    autoplay: true,
    animationData: Tick.default,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const crossOptions = {
    loop: false,
    autoplay: true,
    animationData: Cross.default,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const ListCheckComponent = ({listCheck, tickMotionStopped}) => {
    if (listCheck === 1) {
        return (
            <Lottie
                options={tickOptions}
                isPaused={tickMotionStopped}
                height={60}
                width={60}
            />
        );
    } else if (listCheck === 0) {
        return (
            <Lottie
                options={crossOptions}
                isPaused={tickMotionStopped}
                height={60}
                width={60}
            />
        );
    } else {
        return <div/>;
    }
};

export default Table;